<template>
  <div class="px-8 py-8 container" style="max-width: 1150px;margin:0 auto;">
    <div class="flex flex-wrap">
      <div class="w-full sm:w-4/5">
        <TitleComponent class="py-8" title="UN Gap Test" size="large" />

        <div class="flex flex-wrap mt-8 p-4" style="background-color:#f7f5f4;">
          <div class="w-full">
            <TitleComponent
              class="py-8"
              title="UN Test Description"
              size="medium"
            />
            <a
              :href="require('../../assets/UN/UNGapDetails-min.png')"
              target="_blank"
            >
              <img class="m-auto" src="@/assets/UN/UNGapDetails-min.png" />
            </a>
          </div>
          <div class="w-full">
            <TitleComponent class="py-8" title="Test Purpose" size="medium" />
            <p class="p-light">
              The purpose of the UN Gap Test is to measure the ability of a
              substance to propagate a detonation from defined shock and
              confinement.
            </p>
            <TitleComponent
              class="py-8"
              title="Test Variations"
              size="medium"
            />
            <p class="p-light">
              The UN Gap Test is used in both Test 1 (a) and Test 2 (a). In UN
              Test 1 (a) no gap is between the booster and substance. In UN Test
              2 (a) there is a PMMA spacer between the booster and the
              substance.
            </p>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full">
            <TitleComponent class="pt-8" title="Key Parameters" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Key Parameter</th>
                    <th>Objectives</th>
                    <th>Origin</th>
                    <th>Specs</th>
                  </tr>
                  <tr>
                    <td>Number of trials</td>
                    <td>Sufficient to ensure a repeatable result</td>
                    <td>&nbsp;</td>
                    <td>2 trials</td>
                  </tr>
                  <tr>
                    <td>Booster</td>
                    <td>
                      Provide a strong, repeatable, stable shock front to the
                      top of the sample
                    </td>
                    <td>&nbsp;</td>
                    <td>
                      160 grams of RDX/wax (95/5) or PETN/TNT (50/50), 50 mm
                      diameter, ~50mm length, 1 600 ± 50 kg/m<sup>3</sup>
                      density
                    </td>
                  </tr>
                  <tr>
                    <td>Confining medium (steel tube)</td>
                    <td>
                      Provide confinement, increasing the susceptibility of the
                      substance to detonation; evidence of reaction type
                    </td>
                    <td>&nbsp;</td>
                    <td>
                      <u>UN Gap (new)</u>: Seamless, carbon steel tube 48 ± 2 mm
                      (1.875-in) OD, 4 mm wall (40 ± 2.2 mm (1.5-in) ID), 400 ±
                      5 mm (16-in) long.<br /><u>UN Gap (legacy)</u>:
                      Cold-drawn, seamless, carbon steel tube 47.6mm (1.875-in)
                      OD, 5.6mm wall (36.5mm (1.44-in) ID), 406mm (16-in)
                      long.<br /><u>NOTE:</u> MIL-STD-1751A Method 1041
                      specifies mild steel (SAE 1015 – 1040); TB 700-2 specifies
                      ASTM 1026 or Drawn Over Mandrel (DOM)
                    </td>
                  </tr>
                  <tr>
                    <td>Spacer</td>
                    <td>Separate the steel tube from the witness plate</td>
                    <td>
                      MIL-STD-1751A Method 1041: The small stand-off (0.063-inch
                      gap) between the acceptor and the witness plate was
                      introduced<br />to prevent the witness plate from
                      shattering and thereby facilitate interpretation of test
                      results.
                    </td>
                    <td>1.6 ± 0.2mm (1/16-in) thick</td>
                  </tr>
                  <tr>
                    <td>Witness plate specifications</td>
                    <td>Evidence of reaction type</td>
                    <td>
                      MIL-STD-1751A Method 1041: Some laboratories prefer to use
                      witness plates with larger length and width, e.g., 9-in
                      square, to<br />reduce the likelihood of shattering the
                      witness plate (an acceptable variation)
                    </td>
                    <td>
                      150 ± 10mm (6-in) square and 3mm (1/8-in) thick<br /><u
                        >NOTE:</u
                      >
                      MIL-STD-1751A Method 1041 specifies mild steel (SAE 1015 –
                      1026); TB 700-2 (2012) specifies ASTM 1020 – 1025
                    </td>
                  </tr>
                  <tr>
                    <td>Offset</td>
                    <td>Offset the witness plate from the ground</td>
                    <td>&nbsp;</td>
                    <td>
                      40 x 90 mm (2 x 4 in) wood blocks at the edge of the
                      witness plate
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <TitleComponent class="pt-8" title="Indicators" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Indicators</th>
                    <th>Detection Method</th>
                    <th>Assessment*</th>
                  </tr>
                  <tr>
                    <td>Damage to the witness plate</td>
                    <td>Visual post-test inspection</td>
                    <td>Hole punched through the witness plate: Class 1</td>
                  </tr>
                  <tr>
                    <td>Damage to the steel tube</td>
                    <td>Visual post-test inspection</td>
                    <td>Complete fragmentation of the tube: Class 1</td>
                  </tr>
                </tbody>
              </table>
              <p class="p-light">*OR relationship</p>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap mt-8 pb-6" style="background-color:#f7f5f4;">
          <div class="w-full md:w-1/2 md:pr-2">
            <TitleComponent
              class="pt-8"
              title="Example Photo of Witness Plate Not Holed, Pipe Not Completely Fragmented (-)"
              size="medium"
            />
            <a
              :href="require('../../assets/UN/no-go-ungap-min.jpg')"
              target="_blank"
            >
              <img
                style="width:75%;"
                class="m-auto"
                src="@/assets/UN/no-go-ungap-min.jpg"
              />
            </a>
            <TitleComponent
              class="pt-8"
              title="Example Photo of Holed Witness Plate (+)"
              size="medium"
            />
            <a
              :href="require('../../assets/UN/go-ungap-min.jpg')"
              target="_blank"
            >
              <img
                style="width:75%;"
                class="m-auto"
                src="@/assets/UN/go-ungap-min.jpg"
              />
            </a>
            <TitleComponent
              class="pt-8"
              title="High-Speed Photos of Complete Pipe Fragmentation"
              size="medium"
            />
            <a
              :href="require('../../assets/UN/PictureSummary-UNGap-min.png')"
              target="_blank"
            >
              <img
                style="width:75%;"
                class="m-auto"
                src="@/assets/UN/PictureSummary-UNGap-min.png"
              />
            </a>
          </div>
          <div class="w-full md:w-1/2 md:pl-2">
            <TitleComponent
              class="pt-8"
              title="Example Video of Witness Plate Not Holed (-)"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/88889167?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo88889167"
              allow="autoplay; fullscreen"
              id="player_1"
              name="fitvid0"
            ></iframe>
            <TitleComponent
              class="pt-8"
              title="Example Video of Holed Witness Plate (+)"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/88889166?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo88889166"
              allow="autoplay; fullscreen"
              id="player_2"
              name="fitvid1"
            ></iframe>
          </div>
        </div>
      </div>
      <UnSideBar class="w-full sm:w-1/5"></UnSideBar>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import UnSideBar from "@/components/UnSideBar";

export default {
  components: {
    TitleComponent,
    UnSideBar
  }
};
</script>

<style lang="scss"></style>
