<template>
  <div
    class="mbom-impact-test px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <SideBar class="sm:w-1/5 sm:order-first sm:mt-0 w-full order-last mt-4" />
      <div class="sm:w-4/5 w-full">
        <TitleComponent class="py-8" title="TNT Equivalency" size="large" />

        <div class="flex flex-wrap mt-8 p-4" style="background-color:#f7f5f4;">
          <div class="w-full">
            <TitleComponent class="py-8" title="Test Details" size="medium" />
            <a
              :href="require('../../assets/IPSeries/ShieldTestDetails-min.png')"
              target="_blank"
            >
              <img
                class="m-auto"
                src="@/assets/IPSeries/TNTEquivalencyDetails-min.png"
              />
            </a>
          </div>
          <div class="w-full">
            <TitleComponent class="py-8" title="Test Purpose" size="medium" />
            <p class="p-light">
              The objective of a
              <a
                class="link"
                target="_blank"
                href="http://www.smsenergetics.com/risk-management/facility-siting-and-design"
                >siting evaluation</a
              >
              is to protect personnel and facilities from explosive or other
              highly reactive operations through proper facility design or
              separation. The quantity of explosives and the required distance
              of separation (QD) determines facility siting. The QD tables as
              used by accepted industry and government methodologies (e.g. UBC,
              DoD) are based on TNT. If the TNT equivalence of an explosive is
              less than that of TNT than more of that substance can be located
              at a given site with a given QD specification. Likewise if the TNT
              equivalence of an explosive is greater than TNT then the opposite
              is true. Testing is performed to document a material’s TNT
              equivalence from the ratio of the overpressure at a given distance
              compared to the overpressure from the same mass of TNT at that
              distance.
            </p>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full">
            <TitleComponent class="pt-8" title="Key Parameters" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Key Parameter</th>
                    <th>Objectives</th>
                    <th>Origin</th>
                    <th>Specs</th>
                  </tr>
                  <tr>
                    <td>Substance configuration</td>
                    <td>
                      Ensure that a significant amount of the substance is
                      tested to relate to the pressure of a similarly sized mass
                      of TNT. Configuration can be process specific
                    </td>
                    <td></td>
                    <td>Amount and configuration can be process specific</td>
                  </tr>
                  <tr>
                    <td>Ignition stimulus (cap or booster)</td>
                    <td>
                      Ensure the substance is subjected to a shock stimulus
                      equivalent to a worst case condition under processing/
                      storage conditions
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Overpressure measurement device (e.g. pencil probe)</td>
                    <td>
                      Measure and record the pressure (side-on overpressure) as
                      a function of time upon initiation of the substance
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <TitleComponent class="pt-8" title="Indicators" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Indicators</th>
                    <th>Detection Method</th>
                    <th>Assessment</th>
                  </tr>
                  <tr>
                    <td>Overpressure</td>
                    <td>Review of the overpressure data</td>
                    <td>
                      The measured overpressure value is compared against that
                      expected for TNT (TM5-1300 Figure 2-15 for side-on
                      overpressure from a hemispherical TNT blast)
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap mt-8 pb-6" style="background-color:#f7f5f4;">
          <div class="w-full md:w-1/2 md:pr-2">
            <TitleComponent class="pt-8" title="Example Videos" size="medium" />
            <div class="mb-4">
              <iframe
                class="m-auto"
                src="https://player.vimeo.com/video/88962306?autoplay=0&amp;autopause=0"
                allowfullscreen=""
                title="vimeo88962306"
                allow="autoplay; fullscreen"
                id="player_1"
                name="fitvid0"
              ></iframe>
            </div>
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/88963379?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo88963379"
              allow="autoplay; fullscreen"
              id="player_2"
              name="fitvid1"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import SideBar from "@/components/IPSeriesSideBar";

export default {
  components: {
    TitleComponent,
    SideBar
  }
};
</script>

<style lang="scss"></style>
